import { loadYoutubeApi } from '../../utilities/js/helper';
import './multimedia.scss';

class Multimedia {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-multimedia',
            silentAttr: 'silent',
            fullscreenAttr: 'fullscreen',
            loopAttr: 'loop',
            offsetAttr: 'data-media-offset'
        };

        this.settings = Object.assign({}, settings, options);
        this.$media = element;
        this.$mediaVideo = this.$media.querySelector('[data-youtube]');
        this.trackingCategory = null;
        this.mediaOffset = 0;
    }

    initialize () {
        if (this.$mediaVideo !== null) {
            import('../../utilities/js/youtube-player').then(({ YoutubePlayer }) => {
                this.initYoutubePlayer(YoutubePlayer);
            });
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        const $video = this.$mediaVideo;

        if ($video !== null) {
            const videoPlayer = new YoutubePlayer($video, {
                eventScroller: window.eventScroller,
                onCall: () => {
                    this.$media.classList.add('show--video');
                },
                onStart: (videoId) => {
                },
                onEnd: (videoId) => {
                    if (videoPlayer.player.video.isMuted() === false || videoPlayer.mute === 0) {
                        videoPlayer.destroy();
                        this.$media.classList.remove('show--video');
                    }
                },
                onPause: (videoId, progress) => {
                }
            });
        }
    }
}

export { Multimedia };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-multimedia="root"]');

        if ($$roots.length > 0) {
            loadYoutubeApi();

            window.onYouTubeIframeAPIReady = () => {
                $$roots.forEach($root => {
                    const $rootAPI = new Multimedia($root);
                    $rootAPI.initialize();
                });
            };
        }
    }
});
